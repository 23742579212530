import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

export default class ColumnSorter extends Component {
  static propTypes = {
    title: PropTypes.string,
    col: PropTypes.string,
    dir: PropTypes.string,
    active: PropTypes.bool,
  };

  handleClick = (e) => {
    const toggle = { asc: "desc", desc: "asc" };
    window.location.search = `sort_column=${this.props.col}&sort_direction=${this.props.active ? toggle[this.props.dir] : this.props.dir}`;
  };

  render() {
    return (
      <div className="whitespace-nowrap inline-block cursor-pointer" onClick={this.handleClick}>
        <span className={`border-b ${this.props.active ? "text-green-700 border-green-700" : "border-gray-400"}`}>{this.props.title}</span>
        <Icon name={this.props.dir == "asc" ? "chevron-up" : "chevron-down"} extraClasses={`mr-0 ml-1 ${this.props.active ? "text-black" : "text-gray-300"}`} />
      </div>
    );
  }
}
