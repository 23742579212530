import React, { Component } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";

export default class BarChart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    chartOnly: PropTypes.bool,
    barColor: PropTypes.string,
  };

  static defaultProps = {
    data: [
      { value: 10, label: "Q1" },
      { value: 100, label: "Q2" },
      { value: 1000, label: "Q3" },
      { value: 10000, label: "Q4" },
    ],
    title: "Chart title",
    chartOnly: false,
    barColor: "#6C82FF",
  };

  render() {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
    ChartJS.defaults.font.size = 11;

    return (
      <div>
        {!this.props.chartOnly && <div className="text-sm mb-4 text-gray-400">{this.props.title}</div>}
        <Bar
          options={{
            responsive: true,
            elements: {
              bar: {
                borderRadius: 5,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                ticks: {
                  color: "#aaa",
                  padding: 4,
                },
              },
              y: {
                ticks: {
                  color: "#aaa",
                  padding: 4,
                },
              },
            },
          }}
          data={{
            labels: this.props.data.map((l) => l.label),
            datasets: [
              {
                data: this.props.data.map((v) => v.value),
                backgroundColor: this.props.barColor,
              },
            ],
          }}
        />
        {!this.props.chartOnly && this.props.description && <div className="text-sm mt-4 text-gray-400">{this.props.description}</div>}
      </div>
    );
  }
}
