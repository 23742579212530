import React, { Component } from "react";
import ReactDOM from "react-dom";

export default class Modal extends Component {
  handleClose = () => {
    this.props.onClose();
  };

  ignoreClick = (e) => {
    e.stopPropagation();
  };

  render() {
    return ReactDOM.createPortal(
      <div id="overlay" onClick={this.handleClose}>
        <div className="overlay-content" onClick={this.ignoreClick}>
          {this.props.children}
        </div>
      </div>,
      document.getElementsByTagName("body")[0]
    );
  }
}
