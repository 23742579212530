import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "../lib/axios";

export default class PublishedToggleButton extends Component {
  static propTypes = {
    published: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
  };

  static defaultProps = {
    published: false,
    url: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      published: this.props.published,
      showAlert: this.props.follows,
    };
  }

  toggleFollow = () => {
    axios({
      method: "post",
      url: this.props.url,
    }).then((_response) => {
      this.setState({ published: !this.state.published });
    });
  };

  render() {
    return (
      <div className="text-base bg-white shadow rounded-lg py-3 px-3 mb-8">
        <div className="flex items-center">
          <div className="flex-grow">Profile visible</div>
          <div>
            <label htmlFor="toggle_published" className="switch">
              <input
                type="checkbox"
                onChange={this.toggleFollow}
                name="toggle_published"
                id="toggle_published"
                value="toggle_published"
                data-remote="true"
                checked={this.state.published}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="border-t text-xs pt-1 mt-2 text-gray-400">{this.state.published ? "Your profile is visible to the public" : "Only you can see and edit your profile"}</div>
      </div>
    );
  }
}
