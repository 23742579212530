import React, { Component } from "react";
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";

export default class DateInput extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    name: PropTypes.string.isRequired,
    class: PropTypes.string,
  };

  static defaultProps = {
    defaultValue: null,
    name: '',
    class: '',
  };

  constructor(props) {
    super(props);

    this.state = { selected: this.props.defaultValue && this.props.defaultValue.length > 0 ? new Date(this.props.defaultValue) : null }
  }

  handleDateChange = (date) => {
    this.setState({ selected: date });
  }

  render() {
    return (
      <>
        <DatePicker
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          selected={this.state.selected}
          onChange={this.handleDateChange}
          className={this.props.class}
          placeholderText="mm/dd/yyyy"
        />
        {this.state.selected && (
          <input type="hidden" name={this.props.name} value={this.state.selected.toDateString()} />
        )}
      </>
    );
  }
}
