import React, { Component } from "react";
import PropTypes from "prop-types";
import DropdownButton, { DropdownOption } from "./DropdownButton";
import Icon from "./Icon";
import axios from "../lib/axios";

export default class PermissionsToggle extends Component {
  static propTypes = {
    action: PropTypes.string,
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.number,
    maxValue: PropTypes.number,
    shouldReload: PropTypes.bool,
  };

  static defaultProps = {
    action: null,
    options: [],
    name: "",
    defaultValue: 1,
    maxValue: 2,
    shouldReload: false,
  };

  constructor(props) {
    super(props);
    this.options = ["owner", "private", "public"];
    this.state = { selected: this.options[this.props.defaultValue] };
  }

  valueToProps(value, option) {
    const v = this.options.indexOf(option);
    return {
      isActive: value === v,
      value: option,
    };
  }

  render() {
    return (
      <>
        <DropdownButton onChange={this.changed}>
          <DropdownOption
            {...this.valueToProps(this.props.defaultValue, "public")}
          >
            <Icon name="globe" /> Public
          </DropdownOption>
          <DropdownOption
            {...this.valueToProps(this.props.defaultValue, "private")}
          >
            <Icon name="lock" /> Private
          </DropdownOption>
          <DropdownOption
            {...this.valueToProps(this.props.defaultValue, "owner")}
          >
            <Icon name="eye-off" /> Owner
          </DropdownOption>
        </DropdownButton>
        <input
          type="hidden"
          name={`permission[${this.props.name}]`}
          value={this.state.selected}
        />
      </>
    );
  }

  changed = (option) => {
    this.setState({ selected: option.props.value }, this.submit);
  };

  submit = () => {
    if (this.props.action) {
      const params = { permission: {} };
      params["permission"][this.props.name] = this.state.selected;
      axios.post(this.props.action, params).then((response) => {
        this.props.shouldReload ? location.reload() : null;
      });
    }
  };
}
