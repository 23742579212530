import React, { Component } from "react";
import Icon from "./Icon";
import axios from "../lib/axios";
import PropTypes from "prop-types";
import qs from "qs";

export default class MultiCompanySelector extends Component {
  static propTypes = {
    defaultLogo: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    api: PropTypes.string.isRequired,
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        logoUrl: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    defaultLogo: "",
    id: "id",
    name: "",
    companies: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      query: "",
      suggestedCompanies: [],
      selectedCompanies: this.props.companies,
      selectedSuggestion: 0,
    };
  }

  handleKey = (e) => {
    switch (e.keyCode) {
      case 9:
        this.handleSelect(e);
        break;
      case 13:
        this.handleSelect(e);
        break;
      case 38:
        this.handleUp(e);
        break;
      case 40:
        this.handleDown(e);
        break;
      case 27:
        this.handleCancel(e);
        break;
      default:
        return;
    }
    e.preventDefault();
  };

  handleChange = (e) => {
    this.setState({ query: e.target.value }, this.throttle);
  };

  throttle = () => {
    if (this.state.waiting) {
      return;
    }

    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.search, 300);
  };

  search = () => {
    const param = qs.stringify(
      { query: this.state.query },
      { addQueryPrefix: true, arrayFormat: "brackets" }
    );

    this.setState({ waiting: true }, () => {
      axios.get(this.props.api + param).then((response) => {
        this.setState({
          waiting: false,
          suggestedCompanies: response.data,
        });
      });
    });
  };

  renderSuggestions = () => {
    if (this.state.suggestedCompanies.length === 0) {
      return null;
    }

    return (
      <ul className="suggestion-list">
        {this.state.suggestedCompanies.map((company, i) => (
          <li
            key={i}
            onClick={() => this.handleClick(i)}
            className={`${
              this.state.selectedSuggestion === i ? "bg-white" : ""
            }`}
          >
            {company.logoUrl ? (
              <img
                className="mx-2 w-6 flex-no-grow"
                src={company.logoUrl}
                alt={company.name}
              />
            ) : (
              <img
                className="mx-2 w-6 flex-no-grow"
                src={this.props.defaultLogo}
                alt={company.name}
              />
            )}
            <span className="text-sm">{company.name}</span>
          </li>
        ))}
      </ul>
    );
  };

  renderSelected = () => {
    if (this.state.selectedCompanies.length === 0) {
      return null;
    }

    return (
      <table className="w-full border mt-2">
        {this.state.selectedCompanies.map((company, i) => (
          <tr key={i} className="border-b">
            <td className="bg-gray-100 p-2 w-8">
              <input
                type="checkbox"
                name={this.props.name}
                value={company.id}
                checked
                onChange={() => this.handleRemove(i)}
              />
            </td>
            <td className="p-2 w-14">
              {company.logoUrl ? (
                <img
                  className="mx-2 w-6 flex-no-grow"
                  src={company.logoUrl}
                  alt={company.name}
                />
              ) : (
                <img
                  className="mx-2 w-6 flex-no-grow"
                  src={this.props.defaultLogo}
                  alt={company.name}
                />
              )}
            </td>
            <td>
              <span className="text-sm">{company.name}</span>
            </td>
          </tr>
        ))}
      </table>
    );
  };

  handleRemove = (i) => {
    const selectedCompanies = this.state.selectedCompanies.slice();
    selectedCompanies.splice(i, 1);
    this.setState({ selectedCompanies });
  };

  handleDown = () => {
    this.setState({
      selectedSuggestion: Math.min(
        this.state.suggestedCompanies.length - 1,
        this.state.selectedSuggestion + 1
      ),
    });
  };

  handleUp = () => {
    this.setState({
      selectedSuggestion: Math.max(0, this.state.selectedSuggestion - 1),
    });
  };

  handleSelect = () => {
    const selectedSuggestion =
      this.state.suggestedCompanies[this.state.selectedSuggestion];
    this.handleSelection(selectedSuggestion);
  };

  handleCancel = () => {
    this.setState({ query: "", suggestedCompanies: [] });
  };

  handleClick = (i) => {
    const selectedSuggestion = this.state.suggestedCompanies[i];
    this.handleSelection(selectedSuggestion);
  };

  handleSelection = (selectedSuggestion) => {
    if (
      this.state.selectedCompanies.findIndex(
        (c) => c.id === selectedSuggestion.id
      ) === -1
    ) {
      this.setState({
        query: "",
        suggestedCompanies: [],
        selectedCompanies: [
          selectedSuggestion,
          ...this.state.selectedCompanies,
        ],
      });
    } else {
      this.setState({ query: "", suggestedCompanies: [] });
    }
  };

  render() {
    return (
      <>
        <div>
          <label htmlFor={this.props.id}>
            <Icon name="search" extraClasses="relative z-10 ml-2 mr-0" />
          </label>
          <input
            onKeyDown={this.handleKey}
            value={this.state.query}
            onChange={this.handleChange}
            id={this.props.id}
            className={`form-input pl-8 -ml-6 relative z-0 mb-0 focus:outline-none ${
              this.state.suggestedCompanies.length > 0
                ? "rounded-b-none border-b-0"
                : ""
            }`}
            type="text"
            placeholder="Start typing to search companies..."
            autoComplete="off"
          />
          {this.renderSuggestions()}
          {this.renderSelected()}
        </div>
      </>
    );
  }
}
