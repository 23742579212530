import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "../lib/axios";

export default class EmailNotificationToggle extends Component {
  static propTypes = {
    raisingFunds: PropTypes.bool.isRequired,
    toggleFundingUrl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    raisingFunds: false,
    toggleFundingUrl: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      raisingFunds: this.props.raisingFunds,
    };
  }

  toggleFunding = () => {
    axios({
      remote: true,
      method: "post",
      url: this.props.toggleFundingUrl,
    }).then((_response) => {
      this.setState({ raisingFunds: !this.state.raisingFunds });
    });
  };

  render() {
    return (
      <>
        <div class="flex flex-row">
          <div className="w-2/3">
            <label class="form-label mb-0 mt-1">
              {this.state.raisingFunds
                ? "Yes, we are currently raising capital."
                : "No, we are not raising capital at this time."}
            </label>
          </div>
          <div className="w-1/3 text-right">
            <label htmlFor="toggle_funding" className="switch">
              <input
                type="checkbox"
                onChange={this.toggleFunding}
                name="toggle_funding"
                id="toggle_funding"
                value="toggle_funding"
                data-remote="true"
                checked={this.state.raisingFunds}
              ></input>
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </>
    );
  }
}
