import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";

export default class VisitorInformationCollector extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    company: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired,
  };

  render() {
    return (
      <Modal>
        <h1 className="text-center text-lg mb-4">
          Please enter your information to view <br />
          <span className="font-bold">{this.props.company.name}</span> on Pitch.vc
        </h1>

        <div className="text-center">
          <img src={this.props.company.logo} alt={this.props.company.name} className="mb-4 company-logo mx-auto" />
        </div>

        <form action={this.props.url} method="post">
          <input type="hidden" name="authenticity_token" value={document.querySelector("meta[name='csrf-token']").getAttribute("content")} />

          <label for="visit_name" className="form-label">Your name</label>
          <input type="text" id="visit_name" name="visit[name]" className="form-input" placeholder="Your name" />

          <label for="visit_email" className="form-label">Your email</label>
          <input type="email" id="visit_email" name="visit[email]" className="form-input" placeholder="Your email address" />

          <input className="button mt-4 w-full" type="submit" name="commit" value="View the profile" />

          <p className="mt-4 text-gray-400 text-sm text-center w-3/4 mx-auto">
            This information will be used for Pitch.vc analytics and when you view other profiles on this device.
          </p>
        </form>
      </Modal>
    );
  }
}
