import React, { Component } from "react";
import PropTypes from "prop-types";
import Select, { SelectComponents, StylesConfig } from "react-select";

const colourStyles = {
  control: (styles, { data }) => ({
    ...styles,
    backgroundColor: "#F9FAFB",
    borderColor: "#ddd",
    borderRadius: "8px",
  }),
  input: (styles, { data }) => ({
    ...styles,
    fontSize: "13px",
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    padding: "2px 5px",
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#eee",
      color: "#555",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "12px",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    backgroundColor: "#e6e6e6",
    ":hover": {
      backgroundColor: "#666",
      color: "white",
    },
  }),
  menuList: (styles, { data }) => ({
    ...styles,
    fontSize: "13px",
  }),
  menuPortal: (styles, { data }) => ({
    ...styles,
    zIndex: "1000",
  }),
  placeholder: (styles, { data }) => ({
    ...styles,
    fontSize: "13px",
  }),
};

const Option = (props) => {
  const { data, getStyles, innerRef, innerProps } = props;

  return <div {...innerProps} ref={innerRef} />;
};

export default class TagSelector extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    taxonomy: PropTypes.array.isRequired,
    selected: PropTypes.array,
  };

  static defaultProps = {
    onChange: null,
    taxonomy: [],
    selected: [],
  };

  render() {
    return (
      <Select
        onChange={this.props.onChange}
        defaultValue={this.props.selected}
        closeMenuOnSelect={false}
        isMulti
        options={this.props.taxonomy}
        name={"company[tag_ids]"}
        delimiter={","}
        placeholder={this.props.placeholder || "Start typing to see suggestions..."}
        className="react-select-container"
        classNamePrefix="react-select"
        menuPortalTarget={document.body}
        styles={colourStyles}
      />
    );
  }
}
