import React, { Component } from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
import Icon from "./Icon";
import factoryWithThrowingShims from "prop-types/factoryWithThrowingShims";

export default class ScreenshotsUploadPreview extends Component {
  static propTypes = {
    images: PropTypes.array,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    namePurge: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  };

  static defaultProps = {
    name: "",
    namePurge: "_purge",
    label: "",
    images: [],
  };
  constructor(props) {
    super(props);
    this.state = { uploadedImages: this.props.images, removedImages: [], images: [] };
  }

  trash = (e, index) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      uploadedImages: this.state.uploadedImages.slice(0, index).concat(this.state.uploadedImages.slice(index + 1)),
      removedImages: this.state.removedImages.concat(this.state.uploadedImages[index]),
    });
  };

  renderThumbs = (images) => images.map((image) => <div>{image}</div>);

  render() {
    return (
      <div className="flex flex-col flex-grow">
        <label htmlFor={this.props.id} className="form-label flex-no-grow">
          {this.props.label}
        </label>
        <div className="border bg-gray-100 border-gray-300 p-2 rounded flex-grow relative">
          <input multiple={true} type="file" onChange={this.handleChange} name={this.props.name} id={this.props.id} />
          {this.state.removedImages.map((image) => (
            <input type="hidden" name={this.props.namePurge} value={image.id} />
          ))}
          <Carousel showStatus={false} autoPlay={false} infiniteLoop={true} thumbWidth={110} renderThumbs={this.renderThumbs}>
            {this.state.uploadedImages
              .map((image, index) => (
                <div key={`uploaded-${index}`} className="relative">
                  <span onClick={(e) => this.trash(e, index)} class="trash cursor-pointer absolute top-0 pb-1 pl-1 right-0 bg-gray-100 rounded-bl hover:text-red-500">
                    <Icon name="trash" extraClasses="mr-0" />
                  </span>
                  <img src={image.src} />
                </div>
              ))
              .concat(
                this.state.images.map((image, index) => (
                  <div key={`new-${index}`} className="relative">
                    <img src={image.src} />
                  </div>
                ))
              )}
          </Carousel>
        </div>
      </div>
    );
  }

  handleChange = (e) => {
    const input = e.target;
    if (input.files && input.files.length > 0) {
      Promise.all(
        [].map.call(
          input.files,
          (f) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = resolve;
              reader.readAsDataURL(f);
            })
        )
      ).then((values) => {
        this.setState({ images: values.map((e) => ({ src: e.target.result })) });
      });
    }
  };
}
