import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "../lib/axios";
import cable from "../lib/cable";

export default class VisitTracker extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    visitId: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.subscribe();
    window.addEventListener("beforeunload", this.visitEnded);
    document.addEventListener("turbolinks:before-visit", this.visitEnded);
    document.addEventListener("click", this.clickHandler);
  }

  componentWillUnmount() {
    this.unsubscribe();
    window.removeEventListener('beforeunload', this.visitEnded);
    document.removeEventListener('turbolinks:before-visit', this.visitEnded);
    document.addEventListener('click', this.clickHandler);
  }

  subscribe() {
    this.subscription = cable.subscriptions.create(
      {
        channel: "VisitsChannel",
        visit_id: this.props.visitId,
      },
      { received: (data) => {} }
    );
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  render() {
    return null;
  }

  visitEnded = (e) => {
    this.unsubscribe();
  };

  clickHandler = (e) => {
    if (e.target.id === "company_deck" || document.getElementById("company_deck").contains(e.target)) {
      this.visitDownload(e);
    }
  };

  visitDownload = (e) => {
    axios.put(this.props.url, { visit: { downloaded_deck: true } }).then((response) => {});
  };
}
