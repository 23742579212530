import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import axios from "../lib/axios";

export default class FollowCardButton extends Component {
  static propTypes = {
    follows: PropTypes.bool.isRequired,
    followUrl: PropTypes.string.isRequired,
    entityName: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  };

  static defaultProps = {
    follows: false,
    followUrl: "",
    entityName: "",
    message: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      following: this.props.follows,
      showAlert: false,
    };
  }

  toggleFollow = () => {
    axios({
      method: "post",
      url: this.props.followUrl,
    }).then((_response) => {
      this.setState({ following: !this.state.following });
      this.setState({ showAlert: true });
    });
  };

  render() {
    return (
      <>
        <a onClick={this.toggleFollow} className={this.state.following ? "button-following" : "button-follow"}>
          <Icon extraClasses="mx-0" name={this.state.following ? "check" : "plus"} /> {this.state.following ? "Following" : "Follow"}
        </a>
      </>
    );
  }
}
