import React, { Component } from "react";
import PropTypes from "prop-types";

export default class WatchlistTVDisplay extends Component {
  static propTypes = {
    companies: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentCompany: 0,
      numberOfCompanies: this.props.companies.length,
      companies: this.props.companies,
      slideDuration: 5000,
    };
  }

  componentDidMount() {
    let timer = setInterval(
      () => this.rotateCompany(),
      this.state.slideDuration
    );
  }

  rotateCompany() {
    this.setState({
      currentCompany:
        this.state.currentCompany < this.state.numberOfCompanies - 1
          ? (this.state.currentCompany += 1)
          : 0,
    });
  }

  renderQR(company) {
    return (
      "https://api.qrserver.com/v1/create-qr-code/?size=280x280&data=" +
      company.companyFullUrl +
      "?utm_source=tv"
    );
  }

  renderTags(company) {
    return (
      <div className="flex space-x-2">
        {company.tags.slice(0, 3).map(({ id, name }) => (
          <div
            key={id}
            className="bg-gray-700 rounded text-white text-sm p-1 px-3 whitespace-nowrap"
          >
            {name}
          </div>
        ))}
      </div>
    );
  }

  renderCompany(company) {
    return (
      <div className="flex space-x-16 p-16">
        <div className="w-1/2 space-y-12">
          <div>
            <div className="mb-8">
              {company.logoUrl ? (
                <img
                  src={company.logoUrl}
                  className="mb-8 h-20 w-auto"
                  alt={company.name}
                />
              ) : (
                ""
              )}
              <h1 className="flex font-bold text-8xl">{company.name}</h1>
            </div>
            {this.renderTags(company)}
          </div>

          <h2 className="font-medium leading-normal text-2xl mb-8 text-grey-300 text-pitchorange">
            {company.tagline}
          </h2>
          <div className="text-2xl leading-relaxed">{company.description}</div>
        </div>
        <div className="w-1/2">
          <div className="mb-12 flex justify-end">
            <div className="bg-white rounded-lg flex-grow-0 overflow-hidden">
              <img
                src={this.renderQR(company)}
                alt="View this company profile on Pitch"
                className="h-48 mx-auto p-3 rounded bg-white"
              />

              <div className="bg-pitchorange text-white text-center text-sm p-2">
                Scan for full Pitch profile
              </div>
            </div>
          </div>
          {company.productImageUrl ? (
            <img
              src={company.productImageUrl}
              alt={company.name}
              className="w-full h-auto flex-grow-0"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderCompany(this.state.companies[this.state.currentCompany])}
      </div>
    );
  }
}
