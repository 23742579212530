import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

export default class DropdownButton extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    extraClasses: PropTypes.string,
  };

  static defaultProps = {
    title: null,
    extraClasses: "",
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.dropdownRef = React.createRef();
    this.state = {
      open: false,
      activeChild:
        this.props.children.find((child) => child.props.isActive) ||
        this.props.children[0],
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (
      this.state.open &&
      this.dropdownRef &&
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(e.target)
    ) {
      this.setState({ open: false });
    }
  };

  render() {
    const buttonClasses = ["button button-toggle relative"];
    buttonClasses.push(this.props.extraClasses);
    if (this.state.open) {
      buttonClasses.push("border-b-0 rounded-b-none");
    }
    return (
      <div
        className={buttonClasses.join(" ")}
        ref={this.dropdownRef}
        onClick={this.toggleDropdown}
        onMouseEnter={(e) => this.toggleHover(true)}
        onMouseLeave={(e) => this.toggleHover(false)}
      >
        <div className="flex justify-between items-center">
          {this.state.activeChild}
          <Icon extraClasses="mr-0 ml-1" name="chevron-down" />
        </div>
        {this.state.open && this.renderDropdown()}
      </div>
    );
  }

  renderDropdown() {
    const buttonClasses = ["button-dropdown -mx-px"];
    if (this.state.hovering) {
      buttonClasses.push("button-hover");
    }

    return (
      <div className={buttonClasses.join(" ")}>
        {this.props.children.map((child, index) => (
          <div key={index} onClick={(e) => this.select(index, e)}>
            {child}
          </div>
        ))}
      </div>
    );
  }

  toggleHover = (hovering) => {
    this.setState({ hovering });
  };

  select = (index, e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(
      { activeChild: this.props.children[index], open: false },
      this.changed
    );
  };

  changed = () => {
    this.props.onChange(this.state.activeChild);
  };

  toggleDropdown = (e) => {
    this.setState((state) => ({ open: !state.open }));
  };
}

export class DropdownOption extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    value: PropTypes.string,
  };

  static defaultProps = {
    isActive: false,
    value: "",
  };

  render() {
    return <>{this.props.children}</>;
  }
}
