import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import AttachmentUploadPreview from "./AttachmentUploadPreview";

export default class AttachmentUploadButton extends Component {
  static propTypes = {
    preview: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    attachments: PropTypes.array,
    name: PropTypes.string.isRequired,
    namePurge: PropTypes.string.isRequired,
  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);
    this.state = {
      uploadedAttachments: this.props.attachments.map((a) => ({
        id: a.id,
        image: a.type && a.type.startsWith("image/"),
        src: a.src,
        type: a.type,
        name: a.name,
      })),
      removedAttachments: [],
      attachments: [],
    };
  }

  render() {
    return (
      <>
        <div className="button relative">
          <input
            multiple={true}
            type="file"
            onChange={this.handleChange}
            name={this.props.name}
            id={this.props.id}
            className="opacity-0 z-20 inset-0 h-full absolute w-full"
          />
          {this.state.removedAttachments.map((attachment) => (
            <input type="hidden" name={this.props.namePurge} value={attachment.id} />
          ))}
          <Icon name="paperclip" /> Attach a file
        </div>
        {(this.state.attachments.length > 0 || this.state.uploadedAttachments.length > 0) && (
          <AttachmentUploadPreview
            id={this.props.preview}
            uploadedAttachments={this.state.uploadedAttachments}
            attachments={this.state.attachments}
            trash={this.trash}
          />
        )}
      </>
    );
  }

  handleChange = (e) => {
    const input = e.target;
    if (input.files && input.files.length > 0) {
      Promise.all(
        [].map.call(
          input.files,
          (f) =>
            new Promise((resolve, reject) => {
              if (f.type && f.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onload = (e) => {
                  resolve({ image: true, src: e.target.result });
                };
                reader.readAsDataURL(f);
              } else {
                resolve({ image: false, name: f.name, type: f.type || "unknown", size: f.size });
              }
            })
        )
      ).then((values) => {
        this.setState({ attachments: values });
      });
    }
  };

  trash = (e, index) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      uploadedAttachments: this.state.uploadedAttachments.slice(0, index).concat(this.state.uploadedAttachments.slice(index + 1)),
      removedAttachments: this.state.removedAttachments.concat(this.state.uploadedAttachments[index]),
    });
  };
}
