import React, { Component } from "react";
import PropTypes from "prop-types";
import EndorsementModal from "./EndorsementModal";
import Icon from "./Icon";
import DefaultAvatar from "./DefaultAvatar";

export default class Endorsements extends Component {
  static propTypes = {
    company: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    canEndorse: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentEndorsement: 0,
      endorsementCount: this.props.endorsements.length,
    };
  }

  next = () => {
    this.setState({ currentEndorsement: this.state.currentEndorsement + 1 });
  };

  previous = () => {
    this.setState({ currentEndorsement: this.state.currentEndorsement - 1 });
  };

  taglineOrRole = (endorsement) => {
    if (endorsement.relationship.person.tagline) {
      return endorsement.relationship.person.tagline;
    } else {
      return endorsement.relationship.role.charAt(0).toUpperCase() + endorsement.relationship.role.slice(1) + " at " + this.props.company.name;
    }
  };

  renderEndorsement(endorsement) {
    return (
      <>
        {/* Endorsement display */}
        <div className="rounded-lg shadow-md border border-gray-200 bg-white">
          <div className="flex rounded-t-lg border-b border-gray-200 bg-gray-50">
            <div className="w-16 h-auto">
              {endorsement.relationship.person.avatarUrl ? (
                <img src={endorsement.relationship.person.avatarUrl} className="inline w-16 h-auto rounded-tl-lg" alt={endorsement.relationship.person.name} />
              ) : (
                <DefaultAvatar />
              )}
            </div>
            <div className="flex-grow text-base py-2 px-3">
              {endorsement.relationship.person.name}
              <div className="text-sm text-gray-400">{this.taglineOrRole(endorsement)}</div>
            </div>
          </div>

          <div className="leading-relaxed text-gray-600 text-sm p-4">{endorsement.description}</div>
        </div>

        {/* Endorsement navigation */}
        {this.state.endorsementCount > 1 && (
          <div className="space-x-2 text-right text-sm mt-4 flex">
            <div className="flex-grow"></div>
            <div className="flex">
              {this.state.currentEndorsement > 0 && (
                <a onClick={this.previous} className="border border-gray-300 rounded-full h-6 w-6 text-gray-600 flex justify-center items-center">
                  <Icon name="arrow-left" extraClasses="mr-0" />
                </a>
              )}
              {this.state.currentEndorsement + 1 < this.state.endorsementCount && (
                <a onClick={this.next} className="border border-gray-300 rounded-full h-6 w-6 text-gray-600 flex justify-center items-center">
                  <Icon name="arrow-right" extraClasses="mr-0" />
                </a>
              )}
            </div>
          </div>
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <h2 className="section-headline flex items-center">
          {((this.state.endorsementCount == 0 && this.props.canEndorse) || this.state.endorsementCount > 0) && <div>Endorsements</div>}
          {((this.state.endorsementCount == 0 && this.props.canEndorse) || (this.state.endorsementCount > 0 && this.props.canEndorse)) && (
            <div>
              <EndorsementModal {...this.props} />
            </div>
          )}
        </h2>
        <div className="profile-section">
          {this.state.endorsementCount == 0 && this.props.canEndorse && (
            <div>
              <div className="help help-gray p-4 mb-0">
                <Icon name="pen-tool" />
                Do you know and love {this.props.company.name}? Add an endorsement to their profile!
              </div>
            </div>
          )}

          {this.state.endorsementCount > 0 && this.renderEndorsement(this.props.endorsements[this.state.currentEndorsement])}
        </div>
      </>
    );
  }
}
