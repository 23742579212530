import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "../lib/axios";
import Icon from "./Icon";

export default class PermissionsBanner extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { open: true };
  }

  dismiss = () => {
    axios.put(this.props.url, { user: { seen_permissions_banner: true } }).then((response) => {
      this.setState({ open: false });
    });
  };

  render() {
    return (
      <>
        {this.state.open && (
          <div className="permissions-banner">
            <h2>
              Decide who sees what on your company profile with these visibility levels:
              <span onClick={this.dismiss} className="float-right">
                <Icon name="x" extraClasses="mr-0" />
              </span>
            </h2>
            <dl>
              <dt>
                <Icon name="globe" extraClasses="mr-2" />
                Public:
              </dt>
              <dd>Visible to anyone on the internet</dd>

              <dt>
                <Icon name="lock" extraClasses="mr-2" />
                Private:
              </dt>
              <dd>Only visible with a private sharing link (includes public content)</dd>

              <dt>
                <Icon name="eye-off" extraClasses="mr-2" />
                Owner:
              </dt>
              <dd>Only visible to you and other profile admins</dd>
            </dl>
          </div>
        )}
      </>
    );
  }
}
