import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import Modal from "./Modal";

export default class CompanyJoinModal extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    open: PropTypes.bool,
    options: PropTypes.array,
    company: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  };

  static defaultProps = {
    open: false,
    user: {
      name: undefined,
      email: undefined,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.open,
    };
  }

  close = () => {
    this.setState({ visible: false });
  };

  open = () => {
    this.setState({ visible: true });
  };

  render() {
    return (
      <>
        <div className="border rounded-lg shadow-sm bg-gray-50 text-sm p-3">
          <a onClick={this.open} className="cursor-pointer">
            <Icon name="user-plus" /> Are you a part of the team at {this.props.company.name}? Click here to request access.
          </a>
        </div>

        {this.state.visible && (
          <Modal onClose={this.close}>
            <h1 className="text-center text-lg mb-4">
              Request to join <span className="font-bold">{this.props.company.name}</span>
            </h1>

            <div className="text-center">
              <img src={this.props.company.logo} alt={this.props.company.name} className="mb-4 company-logo mx-auto" />
            </div>

            <form action={this.props.url} method="post">
              <div className="space-y-2">
                <input type="hidden" name="authenticity_token" value={document.querySelector("meta[name='csrf-token']").getAttribute("content")} />

                <label for="member_name" className="form-label">
                  Your name
                </label>
                <input type="text" id="member_name" name="name" className="form-input" placeholder="Your name" readonly disabled value={this.props.user.name} />

                <label for="member_email" className="form-label">
                  Your email
                </label>
                <input type="email" id="member_email" name="email" className="form-input" placeholder="Your email address" readonly disabled value={this.props.user.email} />

                <label for="member_role" className="form-label">
                  Your role at {this.props.company.name}
                </label>
                <select id="member_role" name="role" className="form-input form-select">
                  {this.props.options.map((o, i) => (
                    <option key={i} value={o.toLowerCase()}>
                      {o}
                    </option>
                  ))}
                </select>
              </div>

              <div className="text-center mt-6">
                <input className="button button-primary" type="submit" name="commit" value="Send my request for approval" />
              </div>
            </form>
          </Modal>
        )}
      </>
    );
  }
}
