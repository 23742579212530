import React, { Component } from "react";
import PropTypes from "prop-types";
import qs from "qs";

export default class FollowingFilterCheckbox extends Component {
  static propTypes = {
    pageFilter: PropTypes.string,
    checked: PropTypes.bool,
  };

  static defaultProps = {
    pageFilter: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked,
    };
  }

  componentDidMount() {
    if (document.location.search == "") {
      localStorage.setItem(this.props.pageFilter, 0);
    }

    if (localStorage.getItem(this.props.pageFilter) != 1) {
      this.setState({ checked: this.state.checked });
    } else {
      this.setState({ checked: !this.state.checked });
    }
  }

  handleCheckbox = (event) => {
    if (localStorage.getItem(this.props.pageFilter) != 1) {
      localStorage.setItem(this.props.pageFilter, 1);
    } else {
      localStorage.setItem(this.props.pageFilter, 0);
    }

    this.setState(this.submit(event, window.localStorage.getItem(this.props.pageFilter)));
  };

  submit = (event, checked) => {
    const search = qs.parse(document.location.search, { ignoreQueryPrefix: true }) || {};
    Object.assign(search, {
      page: 1,
      filter: Object.assign({}, search.filter || {}, { following: checked || false }),
    });

    document.location.assign(document.location.pathname + "?" + qs.stringify(search, { arrayFormat: "brackets" }));
  };

  render() {
    return (
      <>
        <label htmlFor="following" className="text-sm rounded bg-white p-2 border flex items-center button">
          <input type="checkbox" onClick={this.handleCheckbox} id="following" data-remote="true" defaultChecked={this.state.checked} className="mr-2"></input>
          Followed by me
        </label>
      </>
    );
  }
}
