import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import axios from "../lib/axios";
import Toast from "./Toast";

export default class FollowButton extends Component {
  static propTypes = {
    follows: PropTypes.bool.isRequired,
    followUrl: PropTypes.string.isRequired,
    entityName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    follows: false,
    followUrl: "",
    entityName: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      following: this.props.follows,
      showAlert: false,
    };
  }

  toggleFollow = () => {
    axios({
      method: "post",
      url: this.props.followUrl,
    }).then((_response) => {
      this.setState({ following: !this.state.following });
      this.setState({ showAlert: true });
    });
  };

  render() {
    let message = {
      followed:
        "You are now following " +
        this.props.entityName +
        ". You will start receiving notifications via email and text whenever meaningful updates are made to the profile.",
      unfollowed:
        "You are no longer following " +
        this.props.entityName +
        ". You will no longer receive notifications via email or text.",
    };

    return (
      <>
        <a
          onClick={this.toggleFollow}
          className={
            this.state.following
              ? "button button-green button-green-filled"
              : "button"
          }
        >
          <Icon
            name={this.state.following ? "check" : "plus"}
            extraClasses="mr-1"
          />{" "}
          {this.state.following ? "Following" : "Follow"}
        </a>
        {this.state.showAlert && (
          <Toast
            message={message[this.state.following ? "followed" : "unfollowed"]}
          ></Toast>
        )}
      </>
    );
  }
}
