import React, { Component } from "react";
import Bar from "./Bar";
import PropTypes from "prop-types";

export default class ProgressBar extends Component {
  static propTypes = {
    percentage: PropTypes.number.isRequired,
  };

  static defaultProps = {
    percentage: 0,
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="text-center w-full my-2">
        <Bar completed={this.props.percentage} />
      </div>
    );
  }
}
