import React, { Component } from "react";
import PropTypes from "prop-types";
import DropdownButton, { DropdownOption } from "./DropdownButton";
import Icon from "./Icon";
import qs from "qs";

export default class SortOptions extends Component {
  static propTypes = {
    extraClasses: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        query: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  static defaultProps = {
    options: [],
    extraClasses: "",
  };

  constructor(props) {
    super(props);
    const search = qs.parse(document.location.search, {
      ignoreQueryPrefix: true,
    });
    const option = this.props.options.find(
      (option) => option.query === search.sort
    );
    this.state = { selected: option ? option.query : null };
  }

  valueToProps(value, option) {
    return {
      isActive: value === option.query,
      value: option.query,
    };
  }

  render() {
    return (
      <DropdownButton
        onChange={this.changed}
        extraClasses={this.props.extraClasses}
      >
        {this.props.options.map((option) => {
          return (
            <DropdownOption
              key={option.label}
              {...this.valueToProps(this.state.selected, option)}
            >
              <Icon name={option.icon} /> {option.label}
            </DropdownOption>
          );
        })}
      </DropdownButton>
    );
  }

  changed = (option) => {
    this.setState({ selected: option.props.value }, this.submit);
  };

  submit = () => {
    const search = qs.parse(document.location.search, {
      ignoreQueryPrefix: true,
    });
    Object.assign(search, { sort: this.state.selected, page: 1 });
    document.location.assign(
      document.location.pathname +
        "?" +
        qs.stringify(search, { arrayFormat: "brackets" })
    );
  };
}
