import React, { Component } from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import Icon from "./Icon";

export default class AttachmentUploadPreview extends Component {
  static propTypes = {
    attachments: PropTypes.array,
    uploadedAttachments: PropTypes.array,
    id: PropTypes.string.isRequired,
    trash: PropTypes.func,
    mimeTypes: PropTypes.any,
  };

  static defaultProps = {
    attachments: [],
    mimeTypes: {
      "video/x-msvideo": "video",
      "video/mp4": "video",
      "video/mpeg": "video",
      "video/quicktime": "video",

      csv: "file-text",
      "application/msword": "file-text",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "file-text",
      "text/plain": "file-text",
      "application/pdf": "file-text",
      "application/vnd.ms-powerpoint": "file-text",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": "file-text",
      "application/vnd.oasis.opendocument.presentation": "file-text",
      "application/vnd.oasis.opendocument.spreadsheet": "file-text",
      "application/vnd.oasis.opendocument.text": "file-text",
      "application/vnd.ms-excel": "file-text",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "file-text",

      "audio/mpeg": "music",

      "application/gzip": "package",
      "application/vnd.rar": "package",
      "application/zip": "package",
      "application/x-7z-compressed": "package",
    },
  };

  iconForMime(t) {
    return this.props.mimeTypes[t] || "file";
  }

  typeFromIcon(t) {
    return { file: "File", video: "Video", music: "Sound", "file-text": "Document", package: "Package" }[t];
  }

  render() {
    return ReactDOM.createPortal(
      <div className="border bg-gray-100 border-gray-300 p-2 rounded flex-grow relative">
        {this.renderPreview()}
      </div>
      ,document.getElementById(this.props.id)
    );
  }

  renderImagePreview(attachment) {
    return <img src={attachment.src} />;
  }

  renderAttachmentPreview(attachment) {
    return (
      <>
        <div class="flex items-center justify-center">
          <Icon name={this.iconForMime(attachment.type)} />
          {this.typeFromIcon(this.iconForMime(attachment.type))}
        </div>
        <div class="truncate text-center text-xs">{attachment.name}</div>
      </>
    );
  }

  renderPreview() {
    const previews = this.props.uploadedAttachments
      .map((attachment, index) => (
        <div key={`uploaded_${index}`} className="w-32 border relative rounded p-2 bg-gray-200 border-gray-300">
          <span onClick={(e) => this.props.trash(e, index)} class="trash cursor-pointer absolute top-0 pb-1 pl-1 pr-1 right-0 bg-gray-100 rounded-bl hover:text-red-500">
            <Icon name="trash" extraClasses="mr-0" />
          </span>
          {attachment.image ? this.renderImagePreview(attachment) : this.renderAttachmentPreview(attachment)}
        </div>
      ))
      .concat(
        this.props.attachments.map((attachment, index) => (
          <div key={index} className="w-32 border rounded p-2 bg-gray-200 border-gray-300">
            {attachment.image ? this.renderImagePreview(attachment) : this.renderAttachmentPreview(attachment)}
          </div>
        ))
      );
    if (previews && previews.length > 0) {
      return <div class="flex flex-wrap gap-2 items-center">{previews}</div>;
    } else {
      return null;
    }
  }
}
