import React, { Component } from "react";
import PropTypes from "prop-types";
import Sprite from "../images/feather.svg";

export default class Icon extends Component {
  static propTypes = {
    extraClasses: PropTypes.string,
    name: PropTypes.string,
  };

  render() {
    return (
      <svg className={`icon feather ${this.props.extraClasses}`}>
        <use xlinkHref={`${Sprite}#${this.props.name}`} />
      </svg>
    );
  }
}
