import React, { Component } from "react";
import ProductVideoPlayer from "./ProductVideoPlayer";
import PropTypes from "prop-types";

export default class VideoInputPreview extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    defaultValue: null,
    placeholder: "Paste your url here",
  };

  constructor(props) {
    super(props);
    this.state = { url: this.props.defaultValue };
  }

  render() {
    return (
      <>
        <label htmlFor={this.props.id} class="form-label">
          {this.props.label}
        </label>
        <div class="form-input">
          <input
            type="text"
            class="bg-transparent w-full"
            placeholder={this.props.placeholder}
            name={this.props.name}
            id={this.props.id}
            value={this.state.url}
            onChange={(e) => this.setState({ url: e.target.value })}
          />
          {this.state.url && (
            <div class="mt-2">
              <ProductVideoPlayer url={this.state.url} />
            </div>
          )}
        </div>
      </>
    );
  }
}
