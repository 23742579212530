import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

export default class LogoUploadPreview extends Component {
  static propTypes = {
    image: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    previewCss: PropTypes.string,
    showInput: PropTypes.bool,
  };

  static defaultProps = {
    action: "",
    name: "",
    label: "",
    previewCss: "",
    showInput: false,
  };
  constructor(props) {
    super(props);

    this.state = {
      image: this.props.image,
      imageRemoveChecked: false,
    };
  }

  render() {
    return (
      <div className="flex space-x-2">
        <div className="flex flex-col">
          <div className={`${this.props.previewCss} relative overflow-hidden flex-grow border bg-gray-100 border-gray-300 flex flex-col items-center justify-center`}>
            <input
              type="file"
              onChange={this.handleChange}
              name={this.props.name}
              id={this.props.id}
              className={`${!this.props.showInput ? "opacity-0 z-20 inset-0 h-full absolute w-full" : ""} w-full`}
            />
            <input id="company_remove_logo" type="checkbox" name="company[remove_logo]" className="hidden" checked={this.state.imageRemoveChecked} />
            {this.state.image && <img className="logo-preview" src={this.state.image} />}
            {!this.state.image && <div className="w-full text-xs text-gray-400 default-logo">Add your logo. Select a file (max. 20 MB)</div>}
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <button className="button button-primary" type="button" onClick={this.handleClick}>
            <Icon name="upload" />
            Upload
          </button>

          <button className="button button-danger" type="button" onClick={this.handleRemoveLogo}>
            <Icon name="trash" />
            Remove
          </button>
        </div>
      </div>
    );
  }

  handleRemoveLogo = (e) => {
    e.preventDefault();
    this.setState({
      image: null,
      imageRemoveChecked: true,
    });
  };

  handleClick = (e) => {
    e.preventDefault();
    document.getElementById("company_logo").click();
  };

  handleChange = (e) => {
    const input = e.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image: e.target.result, imageRemoveChecked: false });
      };
      reader.readAsDataURL(input.files[0]);
    }
  };
}
