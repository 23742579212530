import axios from "axios";

(function () {
  const setup = function () {
    const csrfToken = document.querySelector("meta[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  };

  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

  if (document.readyState === "complete") {
    setup();
  } else {
    document.addEventListener("readystatechange", (event) => {
      if (event.target.readyState === "complete") {
        setup();
      }
    });
  }
})();

export default axios;
