import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

export default class ImageUploadPreview extends Component {
  static propTypes = {
    image: PropTypes.any.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    namePurge: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    previewCss: PropTypes.string
  };

  static defaultProps = {
    action: "",
    name: "",
    namePurge: "_purge",
    label: "",
    previewCss: ""
  };
  constructor(props) {
    super(props);
    this.state = {  uploadedImage: this.props.image, removedImage: null, image: null };
  }

  trash = (e) => {
    e.preventDefault();
    e.stopPropagation();

    e.target.dispatchEvent(new Event('change', {bubbles: true}));

    this.setState({
      uploadedImage: null,
      removedImage: this.state.uploadedImage,
    });
  };

  render() {
    return (
      <div className="flex flex-col flex-grow relative">
        <label htmlFor={this.props.id} className="form-label flex-no-grow">
          {this.props.label}
        </label>
        <div className={`${this.props.previewCss} flex-grow border bg-gray-100 border-gray-300 flex flex-col items-center`}>
          <input
            type="file"
            onChange={this.handleChange}
            name={this.props.name}
            id={this.props.id}
            className={`${!this.props.showInput ? "opacity-0 z-20 inset-0 h-full absolute w-full" : ""} w-full`}
          />
          {this.state.removedImage && (
            <input type="hidden" name={this.props.namePurge} value={this.state.removedImage.id} />
          )}

          {(!!this.state.uploadedImage || !!this.state.image) && (
            <div className="relative">
              {this.props.showInput && this.state.uploadedImage && (
                <span onClick={(e) => this.trash(e)} class="trash cursor-pointer absolute top-0 pb-1 pl-1 right-0 bg-gray-100 rounded-bl hover:text-red-500">
                  <Icon name="trash" extraClasses="mr-0" />
                </span>
              )}
              <img className={`${(!!this.state.uploadedImage || !!this.state.image) ? "mt-2" : ""} w-full h-auto`} src={(this.state.uploadedImage || this.state.image).src} />
            </div>
          )}

        </div>
      </div>
    );
  }

  handleChange = (e) => {
    const input = e.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image: { src: e.target.result }, uploadedImage: null, removedImage: this.state.uploadedImage });
      };
      reader.readAsDataURL(input.files[0]);
    }
  };
}
