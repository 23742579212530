import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import Modal from "./Modal";

export default class AddToList extends Component {
  static propTypes = {
    watchlistsUrl: PropTypes.string.isRequired,
    company: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    lists: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
  };

  static defaultProps = {
    watchlistsUrl: "",
    company: {
      id: 0,
      name: "",
    },
    lists: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      select: this.props.lists.length > 0,
      selectedList: "",
    };
  }

  close = () => {
    this.setState({ visible: false, select: this.props.lists.length > 0 });
  };

  open = () => {
    this.setState({ visible: true });
  };

  handleListPick = (e) => {
    if (e.target.value === "+") {
      this.setState({ select: false, selectedList: "" });
    } else {
      this.setState({ selectedList: e.target.value });
    }
  };

  actionUrl() {
    return this.state.select
      ? `${this.props.watchlistsUrl}/${this.state.selectedList}/add_company`
      : this.props.watchlistsUrl;
  }

  renderListSelect() {
    return (
      <>
        <input type="hidden" value={this.props.company.id} name="company_id" />
        <select
          class="form-input"
          name="id"
          value={this.state.selectedList}
          onChange={this.handleListPick}
        >
          <option disabled value="">
            Select a list
          </option>
          {this.props.lists.map((l) => (
            <option value={l.id}>{l.title}</option>
          ))}
          <option value="+">New List...</option>
        </select>
        <input
          type="submit"
          className="button button-primary mt-4"
          value={`Add ${this.props.company.name} to list`}
        />
      </>
    );
  }

  renderListCreate() {
    return (
      <>
        <input
          type="hidden"
          value={this.props.company.id}
          name="watchlist[company_ids][]"
        />
        <input type="hidden" name="redirect_to_company" value="1" />
        <input
          type="text"
          className="form-input"
          name="watchlist[title]"
          placeholder="Type a new list name..."
        />
        <input
          type="submit"
          className="button button-primary mt-4"
          value={`Create list and add ${this.props.company.name} to it`}
        />
      </>
    );
  }

  render() {
    return (
      <>
        <a onClick={this.open} className="button">
          <Icon name="plus-square" extraClasses="mr-1" /> Add to watchlist
        </a>
        {this.state.visible && (
          <Modal onClose={this.close}>
            <form action={this.actionUrl()} method="POST">
              <input
                type="hidden"
                name="authenticity_token"
                value={document
                  .querySelector("meta[name='csrf-token']")
                  .getAttribute("content")}
              />
              <h1 className="section-headline">
                Add {this.props.company.name} to a watchlist
              </h1>
              <p className="text-sm mb-4">
                Once added to a watchlist, this company's public profile will be
                accessible from the list until removed.
              </p>
              {this.state.select && this.renderListSelect()}
              {!this.state.select && this.renderListCreate()}
            </form>
          </Modal>
        )}
      </>
    );
  }
}
