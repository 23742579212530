import React, { Component } from "react";
import PropTypes, { object } from "prop-types";
import axios from "../lib/axios";

export default class NotificationSettingToggle extends Component {
  static propTypes = {
    settingId: PropTypes.array.isRequired,
    notificationSettingsUrl: PropTypes.string.isRequired,
    status: PropTypes.bool.isRequired,
    notificationSettingsStatus: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    settingId: [],
    notificationSettingsUrl: "",
    status: false,
    notificationSettingsStatus: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      suscribed: this.props.status,
    };
  }

  updateEmailNotifications = () => {
    axios({
      remote: true,
      method: "post",
      data: { id: this.props.settingId },
      url: this.props.notificationSettingsUrl,
    }).then((_response) => {
      this.setState({ suscribed: !this.state.suscribed });
    });
  };

  render() {
    return (
      <label htmlFor="update_notification">
        <input
          disabled={!this.props.notificationSettingsStatus}
          type="checkbox"
          onChange={this.updateEmailNotifications}
          name="update_notification"
          id={this.props.settingId}
          value="update_notification"
          checked={this.state.suscribed}
          className="float-right disable"
        ></input>
      </label>
    );
  }
}
