import React, { Component } from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
export default class ProductCarousel extends Component {
  static propTypes = {
    content: PropTypes.array.isRequired,
  };

  render() {
    return (
      <Carousel
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
        stopOnHover={true}
        thumbWidth={110}
        interval={3500}
      >
        {this.props.content.map((value, index) => {
          return (
            <div key={index}>
              <img src={value} />
            </div>
          );
        })}
      </Carousel>
    );
  }
}
