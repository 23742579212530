import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import cable from "../lib/cable";

export default class LinkAwareTextarea extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    inputCss: PropTypes.string,
    placeholder: PropTypes.string,
    linkName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    defaultValue: "",
    placeholder: "",
    inputCss: "form-input h-20",
  };

  constructor(props) {
    super(props);
    this.state = { preview: null, dismissed: [], waiting: null };
  }

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe() {
    this.subscription = cable.subscriptions.create(
      {
        channel: "LinkPreviewsChannel",
      },
      {
        received: (data) => {
          this.unfurled(data);
        },
      }
    );
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  detect = (e) => {
    if (this.state.preview || this.state.waiting) {
      return;
    }
    clearTimeout(this.throttle);
    this.throttle = setTimeout(() => {
      const matches = e.target.value.match(/(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/g);
      if (matches && matches.length > 0) {
        this.unfurl(matches[matches.length - 1]);
      }
    }, 300);
  };

  unfurl(url) {
    if (this.state.dismissed.indexOf(url) !== -1) {
      return;
    }
    this.setState({ waiting: url }, () => this.subscription.perform("unfurl", { url }));
  }

  unfurled(data) {
    if (this.state.waiting === data["url"]) {
      let preview = {
        url: data["url"],
        image_url: data["image_url"],
        name: data["name"],
        description: data["description"],
      };

      if (data["failure"]) {
        preview = null;
      }

      this.setState({
        waiting: null,
        preview: preview,
      });
    }
  }

  dismiss = (e) => {
    this.setState({ preview: null, waiting: null, dismissed: this.state.dismissed.concat(this.state.preview.link) });
  };

  render() {
    let css = this.props.inputCss;
    if (this.state.preview) {
      css = css + " rounded-t rounded-b-none";
    }
    return (
      <>
        <textarea onChange={this.detect} id={this.props.id} name={this.props.name} className={css} placeholder={this.props.placeholder}>
          {this.props.defaultValue}
        </textarea>
        {this.state.preview && this.renderPreview()}
      </>
    );
  }

  renderPreview() {
    return (
      <div className="flex form-link-preview">
        <img className="flex-no-grow w-36" src={this.state.preview.image_url} />
        <div className="flex-grow flex flex-col pl-2">
          <h3 className="flex-no-grow">
            <span className="font-bold">{this.state.preview.name}</span>
            <span onClick={this.dismiss} className="float-right cursor-pointer">
              <Icon name="x" extraClasses="mr-0 " />
            </span>
          </h3>
          <p className="flex-grow">{this.state.preview.description}</p>
        </div>
        <input type="hidden" name={this.props.linkName + "[url]"} value={this.state.preview.url} />
        <input type="hidden" name={this.props.linkName + "[image_url]"} value={this.state.preview.image_url} />
        <input type="hidden" name={this.props.linkName + "[name]"} value={this.state.preview.name} />
        <input type="hidden" name={this.props.linkName + "[description]"} value={this.state.preview.description} />
      </div>
    );
  }
}
