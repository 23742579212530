import React, { Component } from "react";
import PropTypes from "prop-types";
import TagSelector from "./TagSelector";
import qs from "qs";

export default class TagFilter extends Component {
  static propTypes = {
    taxonomy: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    selected: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  static defaultProps = {
    taxonomy: [],
    selected: [],
  };

  constructor(props) {
    super(props);
    // If we want to use async load of results, we need to change this.
    this.state = {
      selected: this.props.selected.map((tag) => tag.value),
    };
  }

  render() {
    return <TagSelector onChange={this.onChange} placeholder={"Start typing..."} {...this.props} />;
  }

  onChange = (value) => {
    this.setState({ selected: value.map((tag) => tag.value) }, this.submit);
  };

  submit = () => {
    const search = qs.parse(document.location.search, { ignoreQueryPrefix: true }) || {};
    Object.assign(search, {
      page: 1,
      filter: Object.assign({}, search.filter || {}, { tags: this.state.selected }),
    });
    document.location.assign(document.location.pathname + "?" + qs.stringify(search, { arrayFormat: "brackets" }));
  };
}
