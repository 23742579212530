import React, { Component } from "react";
import Icon from "./Icon";
import PropTypes from "prop-types";

export default class ToggleElement extends Component {
  static propTypes = {
    toggleClass: PropTypes.string,
    element: PropTypes.string,
    copy: PropTypes.string,
    icon: PropTypes.string,
    linkid: PropTypes.string,
  };

  toggleElement = () => {
    document.getElementById(this.props.element).classList.toggle(this.props.toggleClass);
    document.getElementById(this.props.linkid).classList.toggle("hidden");
  };

  render() {
    return (
      <div id={this.props.linkid} className="text-sm text-gray-500">
        <div className="cursor-pointer" onClick={() => this.toggleElement()}>
          <Icon name={this.props.icon} />
          <span className="underline">{this.props.copy}</span>
        </div>
      </div>
    );
  }
}
