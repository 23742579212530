import React, { Component } from "react";

export default class DefaultAvatar extends Component {
  render() {
    return (
      <div className="avatar-container">
        <div className="rounded-tl-lg bg-gray-200 w-16 h-16 inline-block mr-2 align-middle">&nbsp;</div>
      </div>
    );
  }
}
