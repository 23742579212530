import React, { Component } from "react";
import BarChart from "./BarChart";
import PropTypes from "prop-types";
import axios from "../lib/axios";

export default class ChartEditor extends Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    url: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    companyProfileUrl: PropTypes.string.isRequired,
    barColor: PropTypes.string,
  };

  static defaultProps = {
    data: [
      { value: 10, label: "Q1" },
      { value: 20, label: "Q2" },
      { value: 30, label: "Q3" },
      { value: 40, label: "Q4" },
    ],
  };

  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title,
      description: this.props.description,
      data: this.props.data,
      companyProfileUrl: this.props.companyProfileUrl,
      barColor: this.props.barColor,
    };
  }

  handleTitleChange = (e) => {
    this.setState({ title: e.target.value });
  };

  handleDescriptionChange = (e) => {
    this.setState({ description: e.target.value });
  };

  handleDataPointLabelChange = (index) => (e) => {
    const updatedPoints = this.state.data.map((dp, dpindex) => {
      if (index != dpindex) return dp;
      return { ...dp, label: e.target.value };
    });

    this.setState({
      data: updatedPoints,
    });
  };

  handleDataPointValueChange = (index) => (e) => {
    const updatedPoints = this.state.data.map((dp, dpindex) => {
      if (index != dpindex) return dp;
      return { ...dp, value: e.target.value };
    });

    this.setState({
      data: updatedPoints,
    });
  };

  handleAddDataPoint = () => {
    this.setState({
      data: this.state.data.concat(this.state.data.length > 0 ? this.state.data[this.state.data.length - 1] : [{ value: "100", label: "Q1" }]),
    });
  };

  handleRemoveDataPoint = (index) => {
    this.setState({
      data: this.state.data.filter((dp, dpindex) => index != dpindex),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    axios({
      method: this.props.method,
      url: this.props.url,
      data: {
        title: this.state.title,
        description: this.state.description,
        datapoints: this.state.data,
      },
    }).then((response) => {
      window.location.href = this.state.companyProfileUrl;
    });
  };

  renderDataRow(index, datapoint) {
    return (
      <tr key={index} className="mb-1">
        <td>
          <input className="form-input" type="text" value={datapoint.label} onChange={this.handleDataPointLabelChange(index)}></input>
        </td>
        <td>
          <input className="form-input" type="number" value={datapoint.value} onChange={this.handleDataPointValueChange(index)}></input>
        </td>
        <td>
          <button type="button" className="button button-circle" onClick={() => this.handleRemoveDataPoint(index)}>
            x
          </button>
        </td>
      </tr>
    );
  }

  render() {
    const dataItems = [];

    for (const [index, _value] of this.state.data.entries()) {
      dataItems.push(this.renderDataRow(index, this.state.data[index]));
    }

    return (
      <div className="mb-4 flex space-x-6">
        <div className="w-1/2">
          <h2 className="section-headline">Chart details</h2>
          <form onSubmit={this.handleSubmit}>
            <div className="border border-gray-200 p-4 rounded mb-4">
              <label className="form-label" htmlFor="chart_title">
                Chart title
              </label>

              <input className="form-input" type="text" onChange={this.handleTitleChange} value={this.state.title || ""} name="chart[title]" id="chart_title" autoComplete="off" />

              <label className="form-label" htmlFor="chart_description">
                Chart description
              </label>

              <input
                className="form-input"
                type="text"
                onChange={this.handleDescriptionChange}
                value={this.state.description || ""}
                name="chart[description]"
                id="chart_description"
                autoComplete="off"
              />

              <label className="form-label mt-2" htmlFor="chart_data">
                Chart data
              </label>

              <table className="mb-1">
                <thead className="text-left">
                  <tr>
                    <th className="text-xs font-normal">Legend</th>
                    <th className="text-xs font-normal">Value</th>
                  </tr>
                </thead>
                <tbody>{dataItems}</tbody>
              </table>

              <button type="button" onClick={this.handleAddDataPoint} className="button button-green">
                + Add value
              </button>
            </div>
            <button type="submit" className="button button-primary">
              Save chart
            </button>
          </form>
        </div>
        <div className="w-1/2">
          <h2 className="section-headline">Chart preview</h2>
          <div className="border border-gray-200 p-4 rounded">
            <BarChart chartOnly={false} data={this.state.data} title={this.state.title} description={this.state.description} barColor={this.state.barColor}></BarChart>
          </div>
        </div>
      </div>
    );
  }
}
