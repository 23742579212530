import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Icon from "./Icon";

export default class EndorsementModal extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    open: PropTypes.bool,
    options: PropTypes.array,
    company: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    canEndorse: PropTypes.bool,
  };

  static defaultProps = {
    open: false,
    user: {
      name: undefined,
      email: undefined,
    },
    canEndorse: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.open,
    };
  }

  close = () => {
    this.setState({ visible: false });
  };

  open = () => {
    this.setState({ visible: true });
  };

  render() {
    return (
      <div className="inline">
        {this.props.canEndorse && (
          <div className="flex items-center">
            <a
              onClick={this.open}
              className="bg-zinc-900 hover:bg-gray-500 rounded-full h-6 w-6 text-white text-center ml-3 leading-5 cursor-pointer flex items-center justify-center"
            >
              <Icon name="plus" extraClasses="mr-1" />
            </a>
          </div>
        )}

        {this.state.visible && (
          <Modal onClose={this.close}>
            <h1 className="text-lg mb-4">
              <img
                src={this.props.company.logo}
                alt={this.props.company.name}
                className="h-6 w-auto inline mr-2"
              />
              Endorse {this.props.company.name}
            </h1>

            <div className="help mb-4">
              Tell {this.props.company.name} profile visitors what you think
              about the company. Endorsements are approved by founders, so they
              may take a bit to show up on the profile.
            </div>

            <form action={this.props.url} method="post">
              <div className="space-y-2">
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={document
                    .querySelector("meta[name='csrf-token']")
                    .getAttribute("content")}
                />

                <label for="endorsement_role" className="form-label">
                  Your relationship with {this.props.company.name}
                </label>
                <select
                  id="endorsement_role"
                  name="role"
                  className="form-input form-select"
                >
                  {this.props.options.map((o, i) => (
                    <option key={i} value={o.toLowerCase()}>
                      {o}
                    </option>
                  ))}
                </select>

                <label for="endorsement_copy" className="form-label">
                  Your feedback
                </label>
                <textarea
                  id="endorsement_copy"
                  name="copy"
                  className="form-input"
                  placeholder="Tell us what you think in a sentence or two"
                />
              </div>

              <div className="text-right mt-4">
                <input
                  className="button button-primary"
                  type="submit"
                  name="commit"
                  value="Add my endorsement"
                />
              </div>
            </form>
          </Modal>
        )}
      </div>
    );
  }
}
