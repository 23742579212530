import React, { Component } from "react";
import PropTypes from "prop-types";
import CitySelector from "./CitySelector";
import qs from "qs";

export default class CityFilter extends Component {
  static propTypes = {
    cityList: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    selected: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  static defaultProps = {
    cityList: [],
    selected: [],
  };

  constructor(props) {
    super(props);
    // If we want to use async load of results, we need to change this.
    this.state = {
      selected: this.props.selected.map((city) => city.value),
    };
  }

  render() {
    return (
      <CitySelector
        onChange={this.onChange}
        placeholder={"Start typing..."}
        {...this.props}
      />
    );
  }

  onChange = (value) => {
    this.setState({ selected: value.map((city) => city.value) }, this.submit);
  };

  submit = () => {
    const search =
      qs.parse(document.location.search, { ignoreQueryPrefix: true }) || {};
    Object.assign(search, {
      page: 1,
      filter: Object.assign({}, search.filter || {}, {
        cities: this.state.selected,
      }),
    });
    document.location.assign(
      document.location.pathname +
        "?" +
        qs.stringify(search, { arrayFormat: "brackets" })
    );
  };
}
