import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "../lib/axios";

export default class EmailNotificationToggle extends Component {
  static propTypes = {
    notificationAll: PropTypes.bool.isRequired,
    notificationSettingsUrl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    notificationAll: false,
    notificationSettingsUrl: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      suscribedAll: this.props.notificationAll,
    };
  }

  disableEmailNotifications = (checkboxes) => {
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].disabled = !this.state.suscribedAll;
    }
  };

  writeEmailNotifications = () => {
    axios({
      remote: true,
      method: "post",
      url: this.props.notificationSettingsUrl,
    }).then((_response) => {
      this.setState({ suscribedAll: !this.state.suscribedAll });
      this.disableEmailNotifications(document.getElementsByClassName("disable"));
    });
  };

  render() {
    return (
      <>
        <div className="flex flex-row float-right">
          <label htmlFor="toggle_notification" className="switch">
            <input
              type="checkbox"
              onChange={this.writeEmailNotifications}
              name="toggle_notification"
              id="toggle_notification"
              value="toggle_notification"
              data-remote="true"
              checked={this.state.suscribedAll}
            ></input>
            <span className="slider round"></span>
          </label>
        </div>
      </>
    );
  }
}
