import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

export default class Toast extends Component {
  static propTypes = {
    type: PropTypes.string,
    onDismiss: PropTypes.func,
    message: PropTypes.string,
    title: PropTypes.string,
  };

  static defaultProps = {
    message: null,
    title: "Message",
    dismissable: true,
    type: "notice",
    onDismiss() {},
  };

  constructor(props) {
    super(props);
    this.state = { open: true };

    // Automatically hide after 10 seconds
    setTimeout(() => {
      this.setState({ open: false });
    }, 10000);
  }

  dismiss = () => {
    if (this.props.dismissable) {
      this.setState({ open: false }, this.props.onDismiss);
    }
  };

  message() {
    return {
      __html: this.props.message,
    };
  }

  render() {
    const classes = ["toast cursor-pointer"];
    classes.push(`toast-${this.props.type}`);

    let icons = {
      notice: "👋",
      alert: "🔔",
    };

    return (
      <div>
        {this.state.open && (
          <div className={classes.join(" ")} onClick={this.dismiss}>
            <div className="toast-header flex">
              <div className="flex-grow">
                <span className="mr-2">{icons[this.props.type]}</span>
                {this.props.title}
              </div>
              {this.props.dismissable && (
                <div>
                  <div className="rounded-full bg-white h-4 w-4 opacity-50 text-gray-800 text-center shadow-sm">
                    <Icon name="x" extraClasses="w-3 align-middle block pl-1 opacity-70" />
                  </div>
                </div>
              )}
            </div>
            <div className="toast-content" style={this.props.style} dangerouslySetInnerHTML={this.message()} />
          </div>
        )}
      </div>
    );
  }
}
