import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

export default class CurrencyInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    id: PropTypes.string.isRequired,
  };

  static defaultProps = {
    id: "",
    label: "",
    name: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      type: "text",
      value: this.parse(this.props.value),
      display: this.display(this.parse(this.props.value)),
    };
  }

  parse(input) {
    if (input === null || input === undefined) {
      return null;
    }

    if (typeof input === "string") {
      input = input.replaceAll(/[^\d]/gi, "");
      if (input.length > 0) {
        return parseInt(input);
      } else {
        return null;
      }
    }

    if (typeof input === "number") {
      return input;
    }
  }

  display(input) {
    if (input === null) {
      return "";
    }
    let r = input;
    let out = r === 0 ? "0" : "";
    while (r > 0) {
      let n = r % 1000;
      r = Math.floor(r / 1000);
      if (r > 0) {
        if (n < 10) {
          out = ",00" + n + out;
        } else if (n < 100) {
          out = ",0" + n + out;
        } else {
          out = "," + n + out;
        }
      } else {
        out = n + out;
      }
    }
    return out;
  }

  handleChange = (e) => {
    this.setState({
      display: this.parse(e.target.value),
    });
  };

  handleBlur = (e) => {
    this.setState({
      type: "text",
      value:
        this.parse(e.target.value) === null ? "" : this.parse(e.target.value),
      display: this.display(this.parse(e.target.value)),
    });
  };

  handleFocus = (e) => {
    this.setState({
      type: "number",
      display: this.state.value,
    });
  };

  render() {
    return (
      <>
        <label htmlFor={this.props.id} className="form-label">
          {this.props.label}
        </label>
        <div>
          <Icon name="dollar-sign" extraClasses="relative z-10 ml-2 mr-0" />
          <input
            id={this.props.id}
            className="form-input pl-8 -ml-6 relative z-0"
            type={this.state.type}
            value={this.state.display}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            step="10000"
            placeholder="0,000,000"
          />
        </div>

        <input type="hidden" name={this.props.name} value={this.state.value} />
      </>
    );
  }
}
