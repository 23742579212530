import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import Toast from "./Toast";

export default class CopyButton extends Component {
  static propTypes = {
    text: PropTypes.string,
    content: PropTypes.string.isRequired,
    class: PropTypes.string,
  };

  static defaultProps = {
    text: "Copy",
    class: "button",
  };

  constructor(props) {
    super(props);

    this.state = { copied: false };
  }

  copyToClipboard(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      let t = document.createElement("textarea");
      t.value = textToCopy;
      t.style.position = "fixed";
      t.style.left = "-999999px";
      t.style.top = "-999999px";

      document.body.appendChild(t);
      t.focus();
      t.select();

      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        t.remove();
      });
    }
  }

  handleClick = (e) => {
    this.copyToClipboard(this.props.content).then(this.copySuccess, this.copyFail);
  };

  copySuccess = (e) => {
    this.setState({ copied: true });
  };

  copyFail = (e) => {};

  renderToast = () => {
    return (
      <Toast
        onDismiss={() => {
          this.setState({ copied: false });
        }}
      >
        The link was copied to the clipboard.
      </Toast>
    );
  };

  render() {
    return (
      <>
        <div className={`whitespace-nowrap ${this.props.class}`} onClick={this.handleClick}>
          <Icon name="copy" /> {this.props.text}
        </div>
        {this.state.copied && this.renderToast()}
      </>
    );
  }
}
